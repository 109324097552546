import React, { useState } from 'react';

const AllSelectOptions = ({
  viewOptionSelect,
  setviewOptionSelect,
  viewNameSelectOptions,
  setUserSelected,
  setviewNameSelectOptions,
  viewOptionSelectClassroom,
  setviewOptionSelectClassroom,
  classroomsData,
  setdataSelectClassroom,
  dataSelectClassroom,
  setviewSelectDataUser
}) => {
  const [viewOptionsStudents, setviewOptionsStudents] = useState(false);
  const [viewUsersClassroom, setviewUsersClassroom] = useState(false);
  const [nameSelectClassroom, setnameSelectClassroom] = useState();
  return (
    <div
      className={`select__option-save ${
        viewOptionSelect ? 'select__option-save-close' : ''
      }`}
    >
      <i
        className="bx bx-chevron-down"
        onClick={() => setviewOptionSelect(!viewOptionSelect)}
      ></i>
      <p
        className="select__option-save-selection"
        onClick={() => setviewOptionSelect(!viewOptionSelect)}
      >
        {viewNameSelectOptions}...
      </p>

      <p
        onClick={() => {
          setUserSelected('user');
          setviewNameSelectOptions('MySelf');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        MySelf
      </p>
      <p
        onClick={() => {
          setUserSelected('all');
          setviewNameSelectOptions('All');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        All
      </p>
      <p
        onClick={() => {
          setUserSelected('students');
          setviewNameSelectOptions('Students');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        Students
      </p>
      <p
        onClick={() => {
          setUserSelected('teachers');
          setviewNameSelectOptions('Teachers');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        Teachers
      </p>
      <div
        className={`select__option-save-classroom ${
          viewOptionSelectClassroom ? 'select__option-save-classroom-close' : ''
        }`}
      >
        <i
          className="bx bx-chevron-down"
          onClick={() => setviewOptionSelectClassroom(!viewOptionSelectClassroom)}
        ></i>
        <p
          className="select__option-save-selection"
          onClick={() => {
            setviewOptionSelectClassroom(!viewOptionSelectClassroom);
            setviewUsersClassroom(false);
          }}
        >
          {nameSelectClassroom ? nameSelectClassroom : 'ClassRoom '}
        </p>

        {classroomsData?.data?.listClassRooms?.items.map(item => (
          <p
            value={`${item.id}_${item.name}`}
            key={item.id}
            onClick={() => {
              setdataSelectClassroom(item);
              setUserSelected('classroom');
              setviewNameSelectOptions(item.name);
              setnameSelectClassroom(item.name);
              setviewOptionSelectClassroom(false);
              setviewUsersClassroom(true);
            }}
          >
            {item.name}
          </p>
        ))}
      </div>
      <p
        onClick={() => {
          setUserSelected('level');
          setviewNameSelectOptions('level');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        level test
      </p>
      <p
        onClick={() => {
          setUserSelected('landing');
          setviewNameSelectOptions('landing');
          setviewOptionSelect(!viewOptionSelect);
        }}
      >
        Landing test
      </p>
      
      {viewUsersClassroom ? (
        <div
          className={`select__option-save-classroom   ${
            viewOptionsStudents ? 'select__option-save-classroom-close' : ''
          }`}
          //   style={{ borderTop: '0.1em solid #0000ff' }}
        >
          <i
            className="bx bx-chevron-down"
            onClick={() => setviewOptionsStudents(!viewOptionsStudents)}
          ></i>
          <p
            className="select__option-save-selection"
            onClick={() => setviewOptionsStudents(!viewOptionsStudents)}
          >
            Select Alumno...
          </p>
          <div
            className={`select__option-save-classroom-students ${
              viewOptionsStudents ? 'select__option-save-userCLassroom-close' : ''
            }`}
            style={{ borderTop: '0.1em solid #0000ff' }}
          >
            {dataSelectClassroom?.students?.items?.map(item => (
              <p
                value={`${item.user.id}_${item.user.name}`}
                key={item.id}
                onClick={() => {
                  setviewSelectDataUser(item);
                  setUserSelected('classroom');
                  setviewNameSelectOptions(item.user.name);
                  setviewOptionSelectClassroom(!viewOptionSelectClassroom);
                  setviewOptionSelect(!viewOptionSelect);
                }}
              >
                {item.user.name}
              </p>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AllSelectOptions;
