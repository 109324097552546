const CardCasos = ({ imageSrc, title, text }) => {
  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className=" text-center" style={{ width: '18rem' }}>
        <div className="card-body">
          <div className="mb-3">
            <img
              src={imageSrc}
              alt="Profile"
              className="rounded-circle img-fluid"
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover'
              }}
            />
          </div>
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCasos;
