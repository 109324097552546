import React, { useState } from 'react';
import '../../Exam/ExamEditor/examEditoComponents/ExamModal.scss';
import { IoIosClose } from 'react-icons/io';
import { BsAlignTop } from 'react-icons/bs';

const ExamModal = ({ examUrl, onClose }) => {
  const [backgroundClicked, setBackgroundClicked] = useState(false);

  const handleBackgroundClick = () => {
    setBackgroundClicked(prevState => !prevState); // Alternar entre true y false
  };

  return (
    <div className="exam-modal-overlay">
      <div
        className={`exam-modal-content ${backgroundClicked ? 'custom-background' : ''}`}
      >
        {' '}
        <div className="icon-container">
          <div
            style={{
              fontSize: '20px',
              color: 'grey',
              cursor: 'pointer',
              fontWeight: '600'
            }}
            className="click-changeViewListFilesExam"
            onClick={handleBackgroundClick}
          >
            T
          </div>
          <IoIosClose
            size={35}
            color="grey"
            className="click-closeViewListFilesExam "
            onClick={onClose}
          />
        </div>
        <iframe src={`/take-exam/${examUrl}`} title="Exam" />
      </div>
    </div>
  );
};

export default ExamModal;
