import React, { useState, useEffect, useContext, Fragment } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import { VideoConferenceContext } from './VideoConferenceContext';
import { useUser } from 'components/UserManagment/UserProvider';
import { resolverMediaError } from './Errors';
import { VideoEvents, VoiceStateConnect } from './enum';
import { GRID_DESKTOP_CONFIG } from './VideoConfigs';
import { playSound } from 'helpers/PlaySound';
import Sound from '../../utils/soundConfig';
import { useUserRole } from 'services/cognito.service';
const { CONNECTED } = VoiceStateConnect;

const pathSegments = window.location.pathname.split('/');
const incognito = pathSegments.includes('incognito') || false;

const Room = ({
  socket,
  model,
  callingTeacherOrStudent,
  blockTeacherOrStudent,
  initTeacherOrStudent,
  teachersData,
  studentsData,
  setAnimation,
  classRoomId,
  setCallingVideo,
  setStopRemoteVideoCall,
  dataCallingVideo,
  dataPausedUsers
}) => {
  const { CALLING_VIDEO, EXIT_VIDEO_CALL, VIDEO_CALL_ACCEPTED, VIDEO_CALL_CANCELLED } =
    VideoEvents;

  const {
    roomName,
    token,
    setConected,
    setToken,
    callingVideo,
    stopRemoteVideoCall,
    setIsVoiceOrVideo
  } = useContext(VideoConferenceContext);

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [isAudioMute, setIsAudioMute] = useState(true);
  const [isVideoMute, setIsVideoMute] = useState(true);
  const [first, setfirst] = useState(true);
  const [incognitoUsers, setIncognitoUsers] = useState({});
  const [admin, setAdmin] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);

  useEffect(() => {
    if (Array.isArray(dataCallingVideo)) {
      const isRalphInCall = dataCallingVideo.some(
        user => user.user === 'ralphman1@hotmail.com'
      );

      if (isRalphInCall) {
        setAdmin(true);
        console.log('Ralph en la llamada');
      }
    } else {
      console.error('dataCallingVideo no es un array:', dataCallingVideo);
    }
  }, [dataCallingVideo]);

  //hooks
  const { user } = useUser(); //user global con session activa

  let userRole = useUserRole();

  useEffect(() => {
    if (stopRemoteVideoCall) {
      handleLogout();
    }
  }, [stopRemoteVideoCall]);

  useEffect(() => {
    if (participants.length === 0) {
      callingTeacherOrStudent(user.attributes.sub, 'waitingVideo');
      initTeacherOrStudent(user.attributes.sub, 'active');
    } else {
      blockTeacherOrStudent(user.attributes.sub, 'toUnlock');
      callingTeacherOrStudent(user.attributes.sub, 'video');
      playSound(Sound.VideoLlamada, false, true);
    }
  }, [participants]);

  useEffect(() => {
    if (first && participants.length === 0) {
      switch (userRole.toString()) {
        case 'TEACHER':
          const teacher = teachersData.find(item => item.id === user.attributes.sub);

          blockTeacherOrStudent(user.attributes.sub, teacher.callingBlock ?? 'toUnlock');
          break;
        case 'STUDENT':
          const student = studentsData.find(item => item.id === user.attributes.sub);

          blockTeacherOrStudent(user.attributes.sub, student.callingBlock ?? 'toUnlock');
          break;
      }
    }
  }, [first, userRole, teachersData, studentsData]);

  useEffect(() => {
    socket.on('INCOGNITO_TOGGLED', data => {
      setIncognitoUsers(prevState => {
        const updatedState = { ...prevState };

        if (data.incognito) {
          updatedState[data.user] = true;
        } else {
          delete updatedState[data.user];
        }

        return updatedState;
      });

      setParticipants(prevParticipants => [...prevParticipants]);
    });

    return () => {
      socket.remove('INCOGNITO_TOGGLED');
    };
  }, [socket]);

  useEffect(() => {
    const participantConnected = participant => {
      setfirst(true);
      setParticipants(prevParticipants => [...prevParticipants, participant]);

      if (user.attributes.email) {
        callingTeacherOrStudent(user.attributes.sub, 'video');
      }
    };

    const participantDisconnected = participant => {
      setfirst(false);

      setParticipants(prevParticipants => {
        return prevParticipants.filter(p => p.sid !== participant.sid);
      });

      if (participant.identity === user.attributes.email) {
        callingTeacherOrStudent(user.attributes.sub, 'onblackboard');
        room.disconnect();
      } else {
        if (participants.length === 1) {
          callingTeacherOrStudent(user.attributes.sub, 'waitingVideo');
        } else {
          callingTeacherOrStudent(user.attributes.sub, 'video');
        }
      }
    };

    Video.connect(token, GRID_DESKTOP_CONFIG(roomName)).then(
      room => {
        setRoom(room);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);

        socket.emit('INCOGNITO_TOGGLED', {
          user: user.attributes.email,
          incognito: incognito
        });

        if (room.state === CONNECTED) {
          setIsVoiceOrVideo(true);
          setConected(true);
          setAnimation(true);
          setCallingVideo(true);
          setConected(true);
          setStopRemoteVideoCall(false);
          socket.emit(CALLING_VIDEO, {
            roomId: classRoomId,
            user: user.attributes.email,
            incognito: incognito
          });
          callingVideo && socket.emit(VIDEO_CALL_ACCEPTED, {});
        }

        if (incognito) {
          room.localParticipant.audioTracks.forEach(track => track.track.disable());
          room.localParticipant.videoTracks.forEach(track => track.track.disable());
        }
      },
      error =>
        resolverMediaError(error, () => {
          setTimeout(() => {
            callingTeacherOrStudent(user.attributes.sub, 'onblackboard');
            initTeacherOrStudent(user.attributes.sub, 'InActive');
            setConected(false);
            setAnimation(false);
            setIsVoiceOrVideo(true);
            socket.emit(CALLING_VIDEO, {});
            setIsVoiceOrVideo(true);
            socket.emit(EXIT_VIDEO_CALL, {
              user: user.attributes.email,
              roomId: roomName
            });
          }, 4000);
        })
    );

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === CONNECTED) {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const remoteParticipants = participants
    .filter(participant => !incognitoUsers[participant.identity])
    .map(participant => {
      // Buscar el usuario en pausedUsers
      const pausedUser = dataPausedUsers.find(user => user.user === participant.identity);

      return (
        <Participant
          key={participant.sid}
          participant={participant}
          stopVideo={
            user.attributes.email !== 'ralphman1@hotmail.com' && pausedUser
              ? pausedUser.stopVideo
              : false
          }
        />
      );
    });

  const audioMute = () => {
    room.localParticipant.audioTracks.forEach(publication => {
      isAudioMute ? publication.track.disable() : publication.track.enable();

      setIsAudioMute(!isAudioMute);
    });
  };

  const videoMute = () => {
    room.localParticipant.videoTracks.forEach(publication => {
      if (!admin) {
        isVideoMute ? publication.track.disable() : publication.track.enable();
        setIsVideoMute(!isVideoMute);
      } else {
        if (user.attributes.email === 'ralphman1@hotmail.com') {
          isVideoMute ? publication.track.disable() : publication.track.enable();
          setIsVideoMute(!isVideoMute);
        } else {
          setIsVideoMute(!isVideoMute);
          setStopVideo(prevState => {
            const newState = !prevState;
            socket.emit('PAUSED_VIDEO', {
              roomId: classRoomId,
              user: user.attributes.email,
              stopVideo: newState
            });
            return newState;
          });
        }
      }
    });
  };

  if (room) {
    // Log any Participants already connected to the Room
    room.participants.forEach(participant => {
      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);
      setIsVoiceOrVideo(true);
    });

    // Log new Participants as they connect to the Room
    room.once('participantConnected', participant => {
      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);
      setIsVoiceOrVideo(true);
    });

    // Log Participants as they disconnect from the Room
    room.once('participantDisconnected', participant => {
      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);
      socket.emit(CALLING_VIDEO, {});
      setIsVoiceOrVideo(true);
    });

    room.on('participantConnected', participant => {
      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);
      setIsVoiceOrVideo(true);
    });

    room.on('participantDisconnected', participant => {
      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);
      socket.emit(CALLING_VIDEO, {});
      setIsVoiceOrVideo(true);
    });
  }

  //salir de la videollamada
  const handleLogout = () => {
    if (room) {
      room.localParticipant.tracks.forEach(function (trackPublication) {
        trackPublication.track.stop();
      });

      setAnimation(false);
      setCallingVideo(false);
      setToken(null);
      setConected(false);
      setIsVoiceOrVideo(true);

      playSound(Sound.VideoLlamada, false, true);
      playSound(Sound.VideoLlamadaRemoto, false, true);

      socket.emit(EXIT_VIDEO_CALL, {
        user: user.attributes.email,
        roomId: roomName
      });
      //PARA LOS USUARIO QUE NO SE HAN CONECTADO A LA VIDEOLLAMADA CON TIEMPO.
      socket.emit(VIDEO_CALL_CANCELLED, {
        user: user.attributes.email,
        roomId: roomName
      });

      socket.emit(CALLING_VIDEO, {});

      callingTeacherOrStudent(user.attributes.sub, 'onblackboard');

      room.disconnect();
    }
  };

  return (
    room && (
      <Fragment>
        {!incognito && (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            localParticipant={true}
            isAudioMute={isAudioMute}
            audioMute={audioMute}
            isVideoMute={isVideoMute}
            videoMute={videoMute}
            handleLogout={handleLogout}
            model={model}
            stopVideo={stopVideo}
          />
        )}

        {remoteParticipants}
      </Fragment>
    )
  );
};

export default Room;
