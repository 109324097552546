import { useLazyQuery } from '@apollo/client';
import { EXAM_USER_ID } from 'components/Home/constants';
import { FILES_BY_USERS_WITH_DYNAMIC_MODE } from 'components/Whiteboard/graphQL/queries';
import React, { useState, useContext, useEffect } from 'react';

export const examContext = React.createContext(null);

export const ExamProvider = ({ children }) => {
  const [allExam, setAllExam] = useState([]); // Lista de exámenes
  const [urlExam, setUrlExam] = useState('');
  const [showExam, setShowExam] = useState(false);
  const idExam = EXAM_USER_ID;
  const [getFilesByUsers, { data: filesData, loading, error }] = useLazyQuery(
    FILES_BY_USERS_WITH_DYNAMIC_MODE,
    {
      variables: { userId: idExam, mode: 'exam-landing' },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    getFilesByUsers();
  }, [getFilesByUsers]);

  useEffect(() => {
    if (loading) console.log('Loading files...');
    if (error) console.error('Error fetching files:', error);
    if (filesData) console.log('Fetched files:', filesData);
  }, [filesData, loading, error]);

  useEffect(() => {
    setAllExam(filesData);
  }, [filesData]);

  const contextData = {
    loading,
    error,
    allExam,
    setShowExam,
    setUrlExam,
    showExam,
    urlExam
  };

  return <examContext.Provider value={contextData}>{children}</examContext.Provider>;
};

export const useExams = () => {
  const context = useContext(examContext);
  if (context === undefined) {
    throw new Error('useExams can only be used inside ExamProvider');
  }
  return context;
};
