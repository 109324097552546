import { useUser } from 'components/UserManagment/UserProvider';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useCreateFile } from 'components/Whiteboard/hooks/useCreateFile';
import './ModalSaveExam.scss';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EXAM } from 'components/Exam/ExamEditor/graphQL/mutations';
import { v4 as uuidv4 } from 'uuid';
import {
  LIST_CLASSROOMS,
  LIST_STUDENTS,
  LIST_TEACHERS
} from 'components/AdminDashboard/graphql/queries';
import { User } from 'models';
import AllSelectOptions from './MosalSaveExamComponents/AllSelectOptions';
import { EXAM_USER_ID } from 'components/Home/constants';

export default function ModalSaveExam({
  show,
  onHide,
  colorScheme,
  examId,
  dataExamId,
  createExamSectionRequest,
  createQuestionRequest,
  isLoading
}) {
  const [PopperLoading, setpopperLoading] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);
  const [UserSelected, setUserSelected] = useState('user');
  const { user } = useUser();
  const [loading, setloading] = useState(false);
  const [ExamName, setExamName] = useState('');
  const { createFile } = useCreateFile();
  const [Error, setError] = useState(null);
  const fileExamID = localStorage.getItem('fileExamID');
  const fileNameExam = localStorage.getItem('fileNameExam');
  const [createExamMutation] = useMutation(CREATE_EXAM);
  const [newExamId, setnewExamId] = useState();
  useEffect(() => {
    const newExamUuid = uuidv4();
    setnewExamId(newExamUuid);
  }, [show, examId]);

  const teachersData = useQuery(LIST_TEACHERS);
  const studentsData = useQuery(LIST_STUDENTS);
  const classroomsData = useQuery(LIST_CLASSROOMS);
  const [dataSelectClassroom, setdataSelectClassroom] = useState();
  const [formatExam, setformatExam] = useState('student');
  const [viewOptionSelect, setviewOptionSelect] = useState(false);
  const [viewNameSelectOptions, setviewNameSelectOptions] = useState('Select ...');
  const [viewOptionSelectClassroom, setviewOptionSelectClassroom] = useState(false);
  const [viewOptionFormat, setviewOptionFormat] = useState(false);
  const [viewSelectDataUser, setviewSelectDataUser] = useState();

  const createExam = async id => {
    try {
      const { data } = await createExamMutation({
        variables: { id }
      });
      console.log(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const saveFileMySelf = async () => {
    let fileInput;

    fileInput = {
      userId: user.attributes.sub,
      name: 'Exam: ' + ExamName,
      url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
      mode: `exam-${formatExam}`,
      currentFolder: 'default'
    };

    try {
      // Create new file in DynamoDB
      const { data: fileData } = await createFile({ variables: fileInput });

      console.log(fileData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFileStudents = async () => {
    const allStudents = studentsData.data.listStudents.items;

    for (const student of allStudents) {
      let fileInput;

      fileInput = {
        userId: student.id,
        name: 'Exam: ' + ExamName,
        url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
        mode: `exam-${formatExam}`,
        currentFolder: 'default'
      };

      try {
        // Create new file in DynamoDB
        const { data: fileData } = await createFile({ variables: fileInput });

        console.log(fileData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveFileTeachers = async () => {
    const allTeachers = teachersData.data.listTeachers.items;

    for (const techer of allTeachers) {
      let fileInput;

      fileInput = {
        userId: techer.id,
        name: 'Exam: ' + ExamName,
        url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
        mode: `exam-${formatExam}`,
        currentFolder: 'default'
      };

      try {
        // Create new file in DynamoDB
        const { data: fileData } = await createFile({ variables: fileInput });

        console.log(fileData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveFileLanding = async () => {
    let fileInput;

    fileInput = {
      userId: EXAM_USER_ID,
      name: 'Exam: ' + ExamName,
      url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
      mode: `exam-landing`,
      currentFolder: 'landing'
    };

    try {
      // Create new file in DynamoDB
      const { data: fileData } = await createFile({ variables: fileInput });

      console.log(fileData);
    } catch (error) {
      console.log(error);
    }
  };
  const saveFileLevel = async () => {
    let fileInput;

    fileInput = {
      userId: EXAM_USER_ID,
      name: 'Exam: ' + ExamName,
      url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
      mode: `exam-landing`,
      currentFolder: 'level'
    };

    try {
      // Create new file in DynamoDB
      const { data: fileData } = await createFile({ variables: fileInput });

      console.log(fileData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFileClassroomsUser = async () => {
    let fileInput;

    fileInput = {
      userId: viewSelectDataUser?.user.id,
      name: 'Exam: ' + ExamName,
      url: `${fileExamID === 'new' ? newExamId : fileExamID}`,
      mode: `exam-${formatExam}`,
      currentFolder: 'default'
    };

    try {
      // Create new file in DynamoDB
      const { data: fileData } = await createFile({ variables: fileInput });

      console.log(fileData);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickNewExam = async () => {
    if (UserSelected !== 'landing' && UserSelected !== 'level') {
      saveFileMySelf();
    }

    try {
      if (UserSelected === 'all') {
        saveFileStudents();
        saveFileTeachers();
      } else if (UserSelected === 'students') {
        saveFileStudents();
      } else if (UserSelected === 'teachers') {
        saveFileTeachers();
      } else if (UserSelected === 'classroom') {
        saveFileClassroomsUser();
      } else if (UserSelected === 'landing') {
        saveFileLanding();
      } else if (UserSelected === 'level') {
        saveFileLevel();
      }

      if (fileExamID === 'new') {
        await createExam(newExamId);

        const numberSectionRequesDuplicate = dataExamId?.examData?.totalSections;

        for (let i = 0; i < numberSectionRequesDuplicate; i++) {
          const newCurrentExamSection = uuidv4();
          const examSectionId = await createExamSectionRequest(
            newExamId,
            newCurrentExamSection
          );

          const items = dataExamId.examData.examSections.items[i].questions.items;

          for (const item of items) {
            const newQuestionId = uuidv4();
            await createQuestionRequest({
              id: newQuestionId,
              audio: item.audio,
              correctOptions: item.correctOptions,
              examSectionId: examSectionId,
              image: item.image,
              needsRecording: item.needsRecording,
              optionA: item.optionA,
              optionB: item.optionB,
              optionC: item.optionC,
              optionD: item.optionD,
              statement: item.statement,
              __typename: 'ExamQuestion'
            });
          }
        }
      }
      onHide();
    } catch (error) {
      console.error('Error creating exam:', error);
    }
  };

  return (
    <Modal
      className="modal-save"
      show={show}
      onHide={onHide}
      backdrop={'static'}
      aria-label="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="modal-save-exam"
          style={{
            background: colorScheme === 'dark' ? 'rgba(0, 221, 255, 0.753)' : '#b8b8b87a'
          }}
        >
          <div className="form-container">
            <form onSubmit={e => e.preventDefault()}>
              <div className="inputAndLabel">
                <label
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, sans-serif',
                    marginBottom: 0
                  }}
                >
                  Exam name
                </label>
                <input
                  id={'examName'}
                  className={window.classnames({ error: false })}
                  type={'text'}
                  name={'examName'}
                  defaultValue={fileNameExam}
                  onChange={e => setExamName(e.target.value)}
                  min="1"
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, sans-serif',
                    maxWidth: '70%',
                    fontSize: '1.3rem',
                    color: ' #6f00ff;'
                  }}
                  disabled={loading}
                />
              </div>
              <div className="inputAndLabel">
                <label
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, sans-serif',
                    marginBottom: 0
                  }}
                >
                  save to
                </label>
                <AllSelectOptions
                  viewOptionSelect={viewOptionSelect}
                  setviewOptionSelect={setviewOptionSelect}
                  viewNameSelectOptions={viewNameSelectOptions}
                  setUserSelected={setUserSelected}
                  setviewNameSelectOptions={setviewNameSelectOptions}
                  viewOptionSelectClassroom={viewOptionSelectClassroom}
                  setviewOptionSelectClassroom={setviewOptionSelectClassroom}
                  classroomsData={classroomsData}
                  setdataSelectClassroom={setdataSelectClassroom}
                  dataSelectClassroom={dataSelectClassroom}
                  setviewSelectDataUser={setviewSelectDataUser}
                />
              </div>

              <div className="inputAndLabel">
                <label htmlFor="format">Format</label>
                <div
                  className={`select__option-save ${
                    viewOptionFormat ? 'select__option-save-close' : ''
                  }`}
                  style={{ zIndex: '5' }}
                >
                  <i
                    className="bx bx-chevron-down"
                    onClick={() => setviewOptionFormat(!viewOptionFormat)}
                  ></i>
                  <p
                    className="select__option-save-selection"
                    onClick={() => setviewOptionFormat(!viewOptionFormat)}
                  >
                    {formatExam}...
                  </p>
                  <p
                    onClick={() => {
                      setformatExam('student');
                      setviewOptionFormat(!viewOptionFormat);
                    }}
                  >
                    student
                  </p>
                  <p
                    onClick={() => {
                      setformatExam('edition');
                      setviewOptionFormat(!viewOptionFormat);
                    }}
                  >
                    edition
                  </p>
                </div>
              </div>
              {Error && (
                <div className="error">
                  <label className="errortag">{Error}</label>
                </div>
              )}
            </form>
            <div className="options">
              <button
                className="text-left"
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Roboto, sans-serif',
                  color: ' #6f00ff'
                }}
                type="button"
                onClick={() => {
                  setanchorEl(null);
                  setUserSelected(null);
                  setloading(false);
                  onHide();
                }}
              >
                Back
              </button>

              {loading ? (
                <Spinner className="bran-spinner" animation="border" />
              ) : (
                <button
                  className="text-right"
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, sans-serif',
                    color: ' #6f00ff'
                  }}
                  onClick={() => onClickNewExam()}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
