import './landingExam.css';
import iconNote from '../../assets/whiteboard/icon_note.svg';
import interv from './img/interview icon.png';
import imgP from './img/imgprueba.jpeg';
import CardCasos from './components/cardCasos';
import CardIcon from './components/cardIcon';
import { text } from 'd3';
import { useState } from 'react';
import { EXAM_ID } from 'components/Home/constants';
import { useHistory } from 'react-router-dom';
import styles from './NewLandingPage.module.scss';
import Tachometer from './components/Tachometer';
import { landingConstants } from 'components/NewLandingPage/constants';

const testimonials = [
  {
    imageSrc: 'https://via.placeholder.com/150/0000FF/FFFFFF?text=User1',
    title: 'María López',
    text: 'Es el mejor termometro para saber como va tu ingles. No solo lo uso antes de entrevistas si no que lo uso para ver mi avance gradual'
  },
  {
    imageSrc: 'https://via.placeholder.com/150/008000/FFFFFF?text=User2',
    title: 'Carlos García',
    text: 'Me sorprendio el hecho de que un examen tan breve pueda dar un resultado tan preciso'
  },
  {
    imageSrc: 'https://via.placeholder.com/150/FF0000/FFFFFF?text=User3',
    title: 'Sofía Martínez',
    text: 'Exelente y gratuito... que mas se puede pedir!'
  }
];

const textIcon = [
  {
    text: 'opten una clase de prueba gratis si consigues 60 puntos o mas en el examen'
  },
  {
    text: 'identifica que tan preparado estas para el ambiente laboral'
  },
  {
    text: 'Recibe un feedback inmediato y sugerencias especificas para mejorar tu ingles (todo sin compromiso)'
  }
];

export default function LandingExam() {
  const history = useHistory();
  const [Menus, setMenus] = useState({
    chatList: false,
    icons: true
  });

  const handleRedirect = () => {
    history.push(`/take-exam/${EXAM_ID}`);
  };
  return (
    <div className="h-100 ">
      {/* header seccion*/}
      <div className="w-100 d-flex seccion-header">
        <div className="w-50 text-center p-4 d-flex flex-column">
          <div className="mb-5 ">
            <h1 className="mb-3">Evalua tu nivel de ingles</h1>
            <p>
              Evalua y conversa sobre tu caso directo y en vivo con un experto en
              linguistica
            </p>
          </div>

          <div>
            <div className="d-flex p-4 gap-4 flex-md-row flex-column container-inputs">
              <input
                className="form-control mr-3"
                placeholder="Name"
                type="text"
                name=""
                id=""
              />
              <input
                className="form-control"
                placeholder="Email"
                type="text"
                name=""
                id=""
              />
            </div>

            <button className="border-0 p-2 f-2 tomar-exm">
              <p>Tomar examen</p>
            </button>
          </div>

          <p className="mb-5">resultado inmediato y confidencial</p>

          <p className="b-0 fs-6 mt-5 ">
            mas de 5000 personas han tomado este examen antes de una entrevista importante
          </p>
        </div>
        <div className="w-50 imgHeader"></div>
      </div>
      {/* casos de exito seccion*/}
      <div className="container seccion-casos p-4">
        {/* Primera fila: Imágenes */}
        <div className="row mb-4">
          <div className="col-md-4 d-flex justify-content-center">
            <CardIcon imageSrc={interv} text={textIcon[0].text} />{' '}
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <div className="container mt-5 d-flex justify-content-center">
              <div className=" text-center" style={{ width: '18rem' }}>
                <div className="card-body">
                  <div className="mb-3">
                    <img src={iconNote} alt="Profile" style={{ width: '50px' }} />
                  </div>
                  <p className="card-text">{textIcon[1].text}</p>
                </div>
              </div>
            </div>{' '}
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <CardIcon imageSrc={interv} text={textIcon[2].text} />
          </div>
        </div>

        {/* Segunda fila: Cards */}
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center">
            <CardCasos
              imageSrc={imgP}
              text={testimonials[0].text}
              title={testimonials[0].title}
            />
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <CardCasos
              imageSrc={imgP}
              text={testimonials[1].text}
              title={testimonials[1].title}
            />
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <CardCasos
              imageSrc={imgP}
              text={testimonials[2].text}
              title={testimonials[2].title}
            />
          </div>
        </div>
      </div>

      {/* faqs seccion*/}
      <div className={`seccion-faqs p-5`}>
        <h1>FAQs</h1>
        <div>
          <a href="#">¿Qué parámetro estándar usan para la evaluación?</a>
          <a href="#">¿Cuánto dura el examen?</a>
          <a href="#">¿Hay algún feedback al final del examen?</a>
          <a href="#">¿Se me pedirá algún aporte o compromiso al final del examen?</a>
          <a href="#">¿Puedo tomar el examen más de una vez?</a>
          <a href="#">¿El examen es el mismo o lo varían periódicamente?</a>
          <a href="#">¿Puedo tomar el examen si no sé absolutamente nada de inglés?</a>
          <a href="#">
            ¿Por qué contesté todas las preguntas correctamente y, sin embargo, siento que
            mi inglés no es tan bueno?
          </a>
          <a href="#">¿Por qué mi puntuación es muy por debajo de lo que esperaba?</a>
          <a href="#">¿Por qué mi puntuación es muy por encima de lo que esperaba?</a>
          <a href="#">
            ¿Cómo evalúan mi nivel de conversación si el examen no incluye conversación
            directa?
          </a>
          <a href="#">
            ¿Cómo evalúan mi nivel de pronunciación si el examen no incluye reconocimiento
            de voz?
          </a>
          <a href="#">¿Puedo usar este examen con mis estudiantes de inglés?</a>
          <a href="#">
            ¿Cómo sé si la puntuación alcanzada me sirve para indicar en mi currículum que
            domino el inglés?
          </a>
          <a href="#">¿Pueden ayudarme a mejorar mi inglés?</a>
        </div>
      </div>
      {/* tacometro seccion*/}
      <div className={`seccionTacometro p-4`}>
        <div className={'border'}>
          <Tachometer
            landingPageLink={window.location.href + `/take-exam/${EXAM_ID}`}
            title="Prueba tu nivel"
            defaultDimentions={{ width: 370, height: 200 }}
            forceBackgroundColor={'yellow'}
            archText="TEST YOUR ENGLISH"
          />
          <div className="w-100 d-flex gap-5 justify-content-center mt-5 flex-column ">
            <div onClick={handleRedirect} className={'m-auto bg-dark text-white p-2'}>
              START
            </div>
            <p className="m-auto">Prueba tu nivel</p>
          </div>
        </div>
      </div>
      {/* redes seccion*/}
      <div className={`seccion-redes w-full`}>
        <div className={'seccion-con'}>
          {landingConstants.links.map((item, index) => {
            return (
              <a
                key={index}
                href={item.link}
                className={styles.linkButton}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="imageRedes" src={item.icon} alt="link-icon" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
