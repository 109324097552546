import React, { useState, useRef, useEffect } from 'react';
import { ICONS_COLORS, USER_ROLES } from '../../../enums/constants.enum';
import { ReactComponent as RecolorDropBlack } from 'assets/whiteboard/black/recolor-drop-black.svg';
import { ReactComponent as RecolorDropGrey } from 'assets/whiteboard/grey/recolor-drop-grey.svg';
import { ReactComponent as RecolorDropWhite } from 'assets/whiteboard/white/recolor-drop-white.svg';
import { useUpdateClassRoomIconsColor } from '../hooks/useUpdateIconsColor';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useUserClassRoom } from '../../UserManagment/hooks/useUserClassRoom';
import { useUserRole } from 'services/cognito.service';

const IconsColorTool = ({ color, background }) => {
  const { loading, setClassRoomIconsColor, applyToAllChangeIconsColors } =
    useUpdateClassRoomIconsColor();
  const { isCrystalTheme, assignedClassRoom } = useUserClassRoom();
  const userRole = useUserRole();
  const [showAdminButtons, setShowAdminButtons] = useState(false);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (buttonsRef.current && !buttonsRef.current.contains(event.target)) {
        setShowAdminButtons(false);
      }
    };

    if (showAdminButtons) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAdminButtons]);

  const classRoomId = assignedClassRoom.classRoomId;

  const handleMainClick = () => {
    if (userRole === USER_ROLES.ADMINS && isCrystalTheme) {
      setShowAdminButtons(prev => !prev);
    } else {
      changeModeColor();
    }
  };

  const changeModeColor = () => {
    if (!isCrystalTheme) {
      setClassRoomIconsColor(
        color === ICONS_COLORS.BLACK ? ICONS_COLORS.GREY : ICONS_COLORS.BLACK,
        classRoomId
      );
    } else {
      setClassRoomIconsColor(
        color === ICONS_COLORS.BLACK
          ? ICONS_COLORS.GREY
          : color === ICONS_COLORS.GREY
          ? ICONS_COLORS.WHITE
          : ICONS_COLORS.BLACK,
        classRoomId
      );
    }
  };

  const changeAllModeColor = () => {
    if (!isCrystalTheme) {
      applyToAllChangeIconsColors(
        color === ICONS_COLORS.BLACK ? ICONS_COLORS.GREY : ICONS_COLORS.BLACK
      );
      return;
    }

    applyToAllChangeIconsColors(
      color === ICONS_COLORS.BLACK
        ? ICONS_COLORS.GREY
        : color === ICONS_COLORS.GREY
        ? ICONS_COLORS.WHITE
        : ICONS_COLORS.BLACK
    );
  };

  if (loading) {
    return <LoadingSpinner customClasses={isCrystalTheme ? 'text-light' : null} />;
  }

  const styles = {
    adminButtons: {
      position: 'absolute',
      bottom: '150%',
      left: '42%',
      transform: 'translateX(-50%)',
      padding: '20px',
      display: 'flex',
      gap: '10px',
      zIndex: 9999,
      backgroundColor: 'rgba(0, 0, 255, 0.15)',
      borderRadius: '8px'
    },
    adminBtn: {
      border: 'white 2px solid',
      borderRadius: '10px',
      color: 'white',
      padding: '8px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontSize: '14px'
    }
  };

  return (
    <div>
      <div onClick={handleMainClick} style={{ cursor: 'pointer' }}>
        {color === ICONS_COLORS.BLACK && <RecolorDropBlack className="page-icon-item" />}
        {color === ICONS_COLORS.WHITE && <RecolorDropWhite className="page-icon-item" />}
        {color === ICONS_COLORS.GREY && <RecolorDropGrey className="page-icon-item" />}
      </div>

      {userRole === USER_ROLES.ADMINS && showAdminButtons && (
        <div ref={buttonsRef} style={styles.adminButtons}>
          <button onClick={changeModeColor} style={styles.adminBtn}>
            This Classroom
          </button>
          <button onClick={changeAllModeColor} style={styles.adminBtn}>
            All Classroom
          </button>
        </div>
      )}
    </div>
  );
};

export default IconsColorTool;
