// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useUserClassRoom } from 'components/UserManagment/hooks/useUserClassRoom';
import { useUser } from 'components/UserManagment/UserProvider';
import { useUserRole } from 'services/cognito.service';
import HomeIcon from './HomeIcon';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const WhiteBoardInfo = ({ page }) => {
  const { iconsColor, isCrystalTheme, classRoomName, assignedClassRoom } =
    useUserClassRoom();
  const { location } = useHistory();
  const { user } = useUser();
  const userRole = useUserRole();
  const paramPathname = location.pathname.split('/')[2];
  const lengthClassroom = paramPathname.length === 1 ? -2 : -paramPathname.length;

  const idCurrentClassroom = `0${paramPathname}`.slice(lengthClassroom);
  const idUserClassroom = assignedClassRoom.classRoomId;
  const classroom =
    idUserClassroom === idCurrentClassroom
      ? classRoomName.toLowerCase()
      : `classroom ${idCurrentClassroom}`;

  const styles = css`
    .page {
      color: ${iconsColor};
    }

    .info {
      color: ${isCrystalTheme ? 'white' : 'black'};
    }

    .buttonProfile {
      color: rgb(255, 249, 255);
      text-decoration: none;
      padding: 0 7px;
      height: 20px;
      text-align: center;
      cursor: pointer;
      &:active {
        transform: scale(0.95);
      }
        

      .infoWhiteboard {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
    }




    @media (max-width: 676px) {
    .page {
        margin-top: -5px;
        margin-left: 10px;
      }

    .homeIcon {
        margin-top: -30px;
        margin-left: -5px;
        height: 5px;
        width: 15px;
    }

    .buttonProfile {
      display: none;
    }
      .info {
      margin-top: -15px;
      margin-left: 5px;
    }
    
    }
    
  `;

  const infoWhiteboard = {
    username: user.attributes?.name,
    role: userRole.toLowerCase(),
    classRoomName: classroom
  };

  return (
    <section
      css={styles}
      className="d-flex flex-row align-items-center infoWhiteboard"
      style={{ gap: '5px' }}
    >
      <p className="page">{page}</p>
      <div className='homeIcon'>
      {
        <Link to="/">
          <HomeIcon color={iconsColor} />
        </Link>
      }

      </div>
      <Link to="/student-profile" className="buttonProfile">
        profile
      </Link>

      <span className="info font-weight-bold align-self-center">
        {infoWhiteboard.username?.slice(0, 6)}... - {infoWhiteboard.role} - {infoWhiteboard.classRoomName}
      </span>
    </section>
  );
};

export default WhiteBoardInfo;
