import { useUserRole } from 'services/cognito.service';
import { USER_ROLES } from 'enums/constants.enum';
import { useState, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { LIST_NOTES } from 'components/UserManagment/StudentProfile/graphql/queries';
import {
  FETCH_BACKGROUND_NOTE,
  SUBS_ON_CHANGE_BACKGROUND_NOTA,
  SUBS_ON_CREATE_NOTES_USER,
  SUBS_ON_DELETE_NOTES_USER,
  SUBS_ON_UPDATE_NOTES_USER
} from './graphql/queries';
import { useEventAnimateNotes } from 'hooks/useEventAnimateNotes';
import { useSocket } from 'providers/SocketProvider';
import { filterNotesByUser, filterNotesLastDays } from './notes-functions';
import { useUser } from 'components/UserManagment/UserProvider';
import {
  WhiteboardNotesAdmin,
  WhiteboardNotesStudents,
  WhiteboardNotesTeacher
} from './components/NoteRole';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useWhiteBoard } from '../WhiteBoardProvider';

export const NotesMain = ({
  openNotes,
  setOpenNotes,
  setClassWhiteboardContain,
  classWhiteboard,
  setClassWhiteboard,
  classWhiteboardFrame,
  classDraggable,
  isStudent
}) => {
  const role = useUserRole();
  const { user } = useUser();
  const { userId } = useParams();
  const { whiteBoardSocket } = useSocket();
  const onCreateNotes = useSubscription(SUBS_ON_CREATE_NOTES_USER);
  const onDeleteNotes = useSubscription(SUBS_ON_DELETE_NOTES_USER);
  const onUpdateNotes = useSubscription(SUBS_ON_UPDATE_NOTES_USER);

  const { data: note, refetch } = useQuery(LIST_NOTES);
  const { data } = useSubscription(SUBS_ON_CHANGE_BACKGROUND_NOTA, {
    variables: { id: '1' }
  });
  const { data: dataQuery } = useQuery(FETCH_BACKGROUND_NOTE, { variables: { id: '1' } });

  const [intervalMessage, setIntervalMessage] = useState();
  const [notesLength, setNotesLength] = useState(0);

  const BGColor =
    data?.onChangeBackgroundNote?.BackgroundColor ||
    dataQuery?.getBackgroundNote?.BackgroundColor;
  const { onEventAnimateNotes, removeEventAnimateNotes } =
    useEventAnimateNotes(whiteBoardSocket);

  const propsDraggableRole = {
    [USER_ROLES.STUDENTS]: {
      classNames: classWhiteboard,
      classDraggable: classDraggable,
      bgColor: BGColor,
      setOpenNotes: setOpenNotes,
      openNotes: openNotes,
      setNotesLength: setNotesLength,
      note: note,
      refetch: refetch,
      role: role
    },
    [USER_ROLES.TEACHERS]: {
      classNames: classWhiteboard,
      classDraggable: classDraggable,
      bgColor: BGColor,
      setOpenNotes: setOpenNotes,
      openNotes: openNotes,
      classWhiteboardFrame: classWhiteboardFrame,
      setNotesLength: setNotesLength,
      note: note,
      refetch: refetch,
      role: role
    },
    [USER_ROLES.ADMINS]: {
      classNames: classWhiteboard,
      classDraggable: classDraggable,
      bgColor: BGColor,
      setOpenNotes: setOpenNotes,
      openNotes: openNotes,
      classWhiteboardFrame: classWhiteboardFrame,
      setNotesLength: setNotesLength,
      note: note,
      refetch: refetch,
      role: role
    }
  };

  const DraggableRole = {
    [USER_ROLES.STUDENTS]: (
      <WhiteboardNotesStudents {...propsDraggableRole[USER_ROLES.STUDENTS]} />
    ),
    [USER_ROLES.TEACHERS]: <WhiteboardNotesTeacher {...propsDraggableRole[role]} />,
    [USER_ROLES.ADMINS]: <WhiteboardNotesAdmin {...propsDraggableRole[role]} />
  };

  useEffect(() => {
    onEventAnimateNotes(resp => {
      if (isStudent && notesLength !== 0 && openNotes) {
        setOpenNotes(false);
        setClassWhiteboard('whiteboard-notes whiteboard-notes--close');
        setClassWhiteboardContain('whiteboard-container-notes active');

        setIntervalMessage(setTimeout(() => setOpenNotes(true), 1000));

        setIntervalMessage(
          setTimeout(() => {
            setClassWhiteboard('whiteboard-notes');
            setOpenNotes(true);
          }, 1000)
        );
      }

      if (isStudent && notesLength !== 0 && !openNotes) {
        setOpenNotes(openNotes => !openNotes);
        setClassWhiteboard('whiteboard-notes');
        setIntervalMessage(setTimeout(() => setOpenNotes(true), 1000));
      }
    });

    return () => removeEventAnimateNotes();
  }, [whiteBoardSocket, role, notesLength]);

  useEffect(() => {
    if (openNotes) {
      setClassWhiteboard('whiteboard-notes');
      setClassWhiteboardContain('whiteboard-container-notes active');
    } else {
      clearInterval(intervalMessage);
      setClassWhiteboard('whiteboard-notes whiteboard-notes--close');
      setClassWhiteboardContain('whiteboard-container-notes-normal');
    }
  }, [openNotes]);

  useEffect(() => {
    let listFilter = filterNotesLastDays(10, note?.listTextNotes?.items);
    listFilter = filterNotesByUser(listFilter, role, userId ?? user?.attributes?.sub);

    if (listFilter?.length === 0) setNotesLength(0);
    else setNotesLength(listFilter?.length || 0);
  }, [note]);

  useEffect(() => {
    // Verificar si la nota se actualizó
    if (onUpdateNotes.data) {
      const updatedNote = onUpdateNotes.data.onUpdateNotesUser;

      // Verificar si el usuario actual es un estudiante y la nota se actualizó
      if (isStudent && updatedNote) {
        setOpenNotes(true);
        setClassWhiteboard('whiteboard-notes');
        setClassWhiteboardContain('whiteboard-container-notes active');
      }
    }
  }, [onUpdateNotes.data]);

  // Mantener las suscripciones existentes sin alteraciones
  useEffect(() => onCreateNotes && refetch(), [onCreateNotes]);
  useEffect(() => onDeleteNotes && refetch(), [onDeleteNotes]);
  useEffect(() => onUpdateNotes && refetch(), [onUpdateNotes]);

  return <>{DraggableRole[role]}</>;
};
