import { gql } from '@apollo/client';

export const EVALUATIONS_BY_STUDENTS = gql`
  query EvaluationsByStudents($studentId: ID!) {
    evaluationsByStudents(studentId: $studentId) {
      items {
        id
        no
        name
        process
        score
        skills {
          listenning
          speaking
          writting
          reading
        }
        date
        file {
          id
        }
      }
    }
  }
`;

export const FILES_BY_USERS = gql`
  query FilesByUsers($userId: ID!, $sort: ModelSortDirection = ASC, $limit: Int = 1000) {
    filesByUsers(
      userId: $userId
      sortDirection: $sort
      limit: $limit
      filter: {
        and: [{ mode: { ne: "backgroundFile" } }, { mode: { ne: "backgroundFolder" } }]
      }
    ) {
      items {
        id
        userId
        name
        url
        mode
        currentFolder
        updatedAt
      }
    }
  }
`;

export const FILES_BY_USERS_WITH_DYNAMIC_MODE = gql`
  query FilesByUsersWithDynamicMode(
    $userId: ID!
    $sort: ModelSortDirection = ASC
    $limit: Int = 1000
    $mode: String!
  ) {
    filesByUsers(
      userId: $userId
      sortDirection: $sort
      limit: $limit
      filter: { and: [{ mode: { eq: $mode } }] }
    ) {
      items {
        id
        userId
        name
        url
        mode
        currentFolder
        updatedAt
      }
    }
  }
`;


export const BACKGROUND_FILES_BY_USERS = gql`
  query FilesByUsers($userId: ID!, $sort: ModelSortDirection = ASC, $limit: Int = 1000) {
    filesByUsers(
      userId: $userId
      sortDirection: $sort
      limit: $limit
      filter: {
        or: [{ mode: { eq: "backgroundFile" } }, { mode: { eq: "backgroundFolder" } }]
      }
    ) {
      items {
        id
        userId
        name
        url
        mode
        currentFolder
        updatedAt
      }
    }
  }
`;

export const FILES_IDS = gql`
  query FilesIds($userId: ID!) {
    filesByUsers(userId: $userId) {
      items {
        id
      }
    }
  }
`;

export const CLASSROOM_STUDENTS = gql`
  query getClassRoomStudents($id: ID!) {
    getClassRoom(id: $id) {
      students {
        items {
          user {
            id
            name
            email
          }
        }
      }
    }
  }
`;

export const CLASSROOM_BACKGROUND = gql`
  query getClassRoomStudents($id: ID!) {
    getClassRoom(id: $id) {
      id
      backgroundImageUrl
      name
    }
  }
`;

export const ALL_CLASSROOM_BACKGROUND = gql`
  query getClassRoomStudents($id: ID!) {
    getClassRoom(id: $id) {
      id
      backgroundImageUrl
      name
    }
  }
`;

export const LIST_TEACHERS_STUDENTS_AND_CLASSROOMS = gql`
  query listData {
    listTeachers {
      items {
        id
        callingBlock
        callingState
        callingInit
        callingOneBlock
        user {
          id
          email
          name
        }
        classRoomId
        classRoom {
          id
          name
        }
      }
    }
    listStudents {
      items {
        id
        callingBlock
        callingState
        callingInit
        classRoomId
        callingOneBlock
        classRoom {
          id
          name
        }
        user {
          id
          email
          name
        }
      }
    }
    listClassRooms {
      items {
        id
        name
        page
        isDisabled
        teachers {
          items {
            user {
              id
              name
            }
          }
        }
        students {
          items {
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const LIST_STUDENTS_AND_TEACHERS_BY_CLASSROOMS = gql`
  query listDataByClassroom($classRoomId: ID) {
    listStudents(filter: { classRoomId: { eq: $classRoomId } }) {
      items {
        id
        classRoomId
        user {
          id
          email
          name
        }
      }
    }
    listTeachers(filter: { classRoomId: { eq: $classRoomId } }) {
      items {
        id
        classRoomId
        user {
          id
          email
          name
        }
      }
    }
  }
`;

export const SUBS_ON_UPDATE_TEACHERS = gql`
  subscription onUpdateTeacher {
    onUpdateTeacher {
      id
    }
  }
`;
export const SUBS_ON_CREATE_TEACHERS = gql`
  subscription onCreateTeacher {
    onCreateTeacher {
      id
    }
  }
`;
export const SUBS_ON_DELETE_TEACHERS = gql`
  subscription onDeleteTeacher {
    onDeleteTeacher {
      id
    }
  }
`;

export const SUBS_ON_UPDATE_STUDENTS = gql`
  subscription onUpdateStudent {
    onUpdateStudent {
      id
    }
  }
`;
export const SUBS_ON_CREATE_STUDENTS = gql`
  subscription onCreateStudent {
    onCreateStudent {
      id
    }
  }
`;
export const SUBS_ON_DELETE_STUDENTS = gql`
  subscription onDeleteStudent {
    onDeleteStudent {
      id
    }
  }
`;

export const SUBS_ON_UPDATE_CLASSROOMS = gql`
  subscription onUpdateClassroom {
    onUpdateClassroom {
      id
    }
  }
`;
export const SUBS_ON_CREATE_CLASSROOMS = gql`
  subscription onCreateClassroom {
    onCreateClassroom {
      id
    }
  }
`;
export const SUBS_ON_DELETE_CLASSROOMS = gql`
  subscription onDeleteClassroom {
    onDeleteClassroom {
      id
    }
  }
`;
