import { useEffect, useRef, useState } from 'react';

export function useVisibilityObserver() {
  const ref = useRef(null); // Referencia al elemento que quieres observar
  const [inView, setInview] = useState(false);

  useEffect(() => {
    // Función para manejar la visibilidad
    function observeVisibility(element, callback) {
      if (!('IntersectionObserver' in window)) {
        console.warn('Intersection Observer no es compatible con este navegador.');
        return;
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          callback(entry.isIntersecting);
        });
      });

      if (element) {
        observer.observe(element);
        return () => observer.disconnect(); // Desconectar el observer cuando el componente se desmonte
      }
    }

    observeVisibility(ref.current, setInview);
  }, []); // Solo se ejecuta al montar/desmontar

  return { ref, inView };
}
