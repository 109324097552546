import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  LIST_STUDENTS_AND_TEACHERS_BY_CLASSROOMS,
  LIST_TEACHERS_STUDENTS_AND_CLASSROOMS,
  SUBS_ON_CREATE_CLASSROOMS,
  SUBS_ON_CREATE_STUDENTS,
  SUBS_ON_CREATE_TEACHERS,
  SUBS_ON_DELETE_CLASSROOMS,
  SUBS_ON_DELETE_STUDENTS,
  SUBS_ON_DELETE_TEACHERS,
  SUBS_ON_UPDATE_CLASSROOMS,
  SUBS_ON_UPDATE_STUDENTS,
  SUBS_ON_UPDATE_TEACHERS
} from 'components/Whiteboard/graphQL/queries';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { objList } from './actions-mockup';
import { TEACHER_VISITING_CLASSROOM } from 'components/Whiteboard/graphQL/mutations';
import { useUser } from 'components/UserManagment/UserProvider';



export const ButtonActions = () => {
  const [actionState, setActionState] = useState(objList);
  const [dataSave, setDataSave] = useState({
    tab_1: { id: null, value: null },
    tab_2: { id: null, value: null },
    tab_3: { id: null, value: null }
  });
  const {
    data,
    refetch: refetchData,
    loading
  } = useQuery(LIST_TEACHERS_STUDENTS_AND_CLASSROOMS);
  const { refetch: refetchDataByClassroom } = useQuery(
    LIST_STUDENTS_AND_TEACHERS_BY_CLASSROOMS
  );
  const [loader, setLoader] = useState(loading);
  const { location, push } = useHistory();
  const onUpdateTeachers = useSubscription(SUBS_ON_UPDATE_TEACHERS);
  const onCreateTeachers = useSubscription(SUBS_ON_CREATE_TEACHERS);
  const onDeleteTeachers = useSubscription(SUBS_ON_DELETE_TEACHERS);
  const onUpdateStudents = useSubscription(SUBS_ON_UPDATE_STUDENTS);
  const onCreateStudents = useSubscription(SUBS_ON_CREATE_STUDENTS);
  const onDeleteStudents = useSubscription(SUBS_ON_DELETE_STUDENTS);
  const onUpdateClassrooms = useSubscription(SUBS_ON_UPDATE_CLASSROOMS);
  const onCreateClassrooms = useSubscription(SUBS_ON_CREATE_CLASSROOMS);
  const onDeleteClassrooms = useSubscription(SUBS_ON_DELETE_CLASSROOMS);
  const [teacherVisitingClassroomMutation] = useMutation(TEACHER_VISITING_CLASSROOM);
  const { user } = useUser();

  const effectChangeData = [
    onUpdateTeachers,
    onCreateTeachers,
    onDeleteTeachers,
    onUpdateStudents,
    onCreateStudents,
    onDeleteStudents,
    onUpdateClassrooms,
    onCreateClassrooms,
    onDeleteClassrooms
  ];

  const handleChangeAction = (action, value, label) => {
    if (loader) return;

    setActionState(actionState =>
      actionState.map(el => {
        let objResponse = {};

        if (el.id === action && value === 'select') {
          return (objResponse = {
            ...el,
            select: !el.select,
            list: el?.list.map(el => ({
              ...el,
              list: el?.list?.map(item => ({
                ...item,
                select: item.value === value ? true : false
              })),
              select: el?.list?.length && el.value === value
            }))
          });
        }

        if (el.id === action) {
          let anyList = el.list.find(item => item.value === value)?.list ? true : false;
          let itemFound = el.list.find(item => item.value === value);

          let isItemSelect = itemFound && anyList ? false : value === 'select';
          let isItemUndefined =
            el.list.find(item => item.value === el.value) && anyList && el.select;
          let currentData =
            el.list.find(item => item.value === el.value) || el.value === 'select'
              ? false
              : true;
          let itemValue = isItemSelect ? (isItemUndefined ? 'select' : el.value) : value;
          let itemLabel = isItemSelect ? (isItemUndefined ? 'Select' : el.label) : label;

          if (action === 'tab_1') {
            if (value === 'profile') {
              setDataSave(dataSave => ({
                ...dataSave,
                tab_1: { id: value, value: value }
              }));
            } else if (value === 'classroom' || dataSave.tab_1.id === 'classroom') {
              if (itemFound) {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_1: { id: value, value: currentData ? el.value : null }
                }));
              } else {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_1: { ...dataSave.tab_1, value }
                }));
              }
            }
          } else if (action === 'tab_2') {
            let elementSelect = el.list.find(item => item.select)?.value;

            if (
              value === 'teacher' ||
              dataSave.tab_2.id === 'teacher' ||
              elementSelect === 'teacher'
            ) {
              if (itemFound && !currentData) {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_2: {
                    id:
                      currentData && value !== dataSave.tab_2.id
                        ? dataSave.tab_2.id
                        : value,
                    value: currentData ? el.value : null
                  }
                }));
              }

              if (value !== 'select' && elementSelect) {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_2: { id: 'teacher', value: value }
                }));
              }
            }

            if (
              value === 'student' ||
              dataSave.tab_2.id === 'student' ||
              elementSelect === 'student'
            ) {
              if (itemFound && !currentData) {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_2: {
                    id:
                      currentData && value !== dataSave.tab_2.id
                        ? dataSave.tab_2.id
                        : value,
                    value: currentData ? el.value : null
                  }
                }));
              }

              if (value !== 'select' && elementSelect) {
                setDataSave(dataSave => ({
                  ...dataSave,
                  tab_2: { id: 'student', value: value }
                }));
              }
            }
          } else if (action === 'tab_3') {
            if (value !== 'select') {
              setDataSave(dataSave => ({
                ...dataSave,
                tab_3: { id: 'mode', value: value }
              }));
            }
          }

          objResponse = {
            ...el,
            value:
              currentData && !itemFound
                ? itemFound || value === 'select'
                  ? el.value
                  : value
                : itemFound || value === 'select'
                ? anyList
                  ? el.value
                  : itemValue
                : itemValue,
            label:
              currentData && !itemFound
                ? itemFound || value === 'select'
                  ? el.label
                  : label
                : itemFound || value === 'select'
                ? anyList
                  ? el.label
                  : itemLabel
                : itemLabel,
            list: el?.list.map(el => ({
              ...el,
              list: el?.list?.map(item => ({
                ...item,
                select: item.value === value ? true : false
              })),
              select: el?.list?.length && el.value === value
            })),
            select:
              value === 'select'
                ? !el.select
                : el?.list.find(el => el.value === value)
                ? anyList
                  ? true
                  : false
                : false
          };
        } else {
          objResponse = { ...el };
        }

        return objResponse;
      })
    );
  };

  const saveDataTabs = (listTeachers, listStudents) => {
    const dataInitial = actionState.map(tab => {
      if (tab.id === 'tab_2') {
        const returnObj = {
          ...tab,
          list: tab.list.map(item => {
            if (item.value === 'teacher') {
              return {
                ...item,
                list: listTeachers.map(el => ({
                  value: el.user.id,
                  label: el.user.name,
                  select: false
                }))
              };
            }

            if (item.value === 'student') {
              return {
                ...item,
                list: listStudents.map(el => ({
                  value: el.user.id,
                  label: el.user.name,
                  select: false
                }))
              };
            }

            return item;
          })
        };
        return returnObj;
      } else {
        return tab;
      }
    });

    setActionState(dataInitial);
  };

  const redirectWhiteboard = async () => {
    const idParam = location.pathname.split('/')[2];
    const incognitoParam = dataSave.tab_3.value === 'incognito';


    if (
      !(dataSave.tab_1.value && dataSave.tab_1.id) &&
      !(dataSave.tab_2.value && dataSave.tab_2.id)
    )
      return alert('Items were not selected');
      
    

    if (dataSave.tab_1.value && dataSave.tab_1.id && dataSave.tab_1.id !== 'profile') {
      await teacherVisitingClassroomMutation({
        variables: {
          teacherId: user.attributes?.sub,
          classRoomIdVisiting: dataSave.tab_1.value
        }
      });

      const incognitoQuery = incognitoParam ? '/incognito' : '';
      window.location.assign(
        dataSave?.tab_2?.value
          ? `/whiteBoard/${dataSave.tab_1.value}/${dataSave?.tab_2?.value}${incognitoQuery}`
          : `/whiteBoard/${dataSave.tab_1.value}${incognitoQuery}`
      );
    } else {
      if (dataSave.tab_2.value === 'CLASSROOM_NOT_ASSIGNED')
        return alert(dataSave.tab_2.value);
      if (!dataSave.tab_2.value) return alert('Select a student or teacher');
      if (dataSave.tab_2.value === idParam) return alert('You are already in the room');
      push(`/student-profile/${dataSave.tab_2.value}`);
    }
  };

  const fetchChangeData = async () => {
    let listTeachers, listStudents;

    if (dataSave.tab_1.id === 'profile' && dataSave.tab_1.value) {
      setLoader(true);
      let fetchData = await refetchData();
      listTeachers = fetchData?.data?.listTeachers.items;
      listStudents = fetchData?.data?.listStudents.items;
      setLoader(fetchData.loading);
      saveDataTabs(listTeachers, listStudents);
    } else if (dataSave.tab_1.id === 'classroom' && dataSave.tab_1.value) {
      setLoader(true);
      let fetchData = await refetchDataByClassroom({ classRoomId: dataSave.tab_1.value });
      listTeachers = fetchData?.data?.listTeachers.items;
      listStudents = fetchData?.data?.listStudents.items;
      setLoader(fetchData.loading);
      saveDataTabs(listTeachers, listStudents);
    }
  };

  const fetchDataInitial = () => {
    const dataInitial = actionState.map(tab => {
      if (tab.id === 'tab_1') {
        const returnObj = {
          ...tab,
          list: tab.list.map(item => {
            if (item.value === 'classroom') {
              return {
                ...item,
                list: data.listClassRooms.items
                  .map(el => ({
                    value: el.id,
                    label: el.name,
                    select: false
                  }))
                  .sort((a, b) => +a.value - +b.value)
              };
            } else {
              return item;
            }
          })
        };

        return returnObj;
      } else if (tab.id === 'tab_2') {
        const returnObj = {
          ...tab,
          list: tab.list.map(item => {
            if (item.value === 'teacher') {
              return {
                ...item,
                list: data.listTeachers.items.map(el => ({
                  value: el.classRoomId,
                  label: el.user.name,
                  select: false
                }))
              };
            }

            if (item.value === 'student') {
              return {
                ...item,
                list: data.listStudents.items.map(el => ({
                  value: el.classRoomId,
                  label: el.user.name,
                  select: false
                }))
              };
            }
          })
        };
        return returnObj;
      } else {
        return tab;
      }
    });
    setActionState(dataInitial);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchChangeData(), effectChangeData);

  useEffect(() => {
    fetchChangeData();
  }, [dataSave.tab_1]);

  useEffect(() => {
    fetchDataInitial();
    return () => {};
  }, []);

  return (
    <>
      {actionState.map((tab, index) => (
        <div
          className={classNames('admin-visits-actions', tab.select && 'active', `button-data-${index}`)}
          key={index}
        >
          <div
            key={tab.id}
            className={`button--purper button--contained`}
            onClick={() => handleChangeAction(tab.id, 'select', tab.label)}
          >
            {loader ? 'Loading...' : tab.label}
          </div>

          <div className="contained-buttons">
            {tab.list.find(el => el.select === true)
              ? tab.list
                  .find(el => el.select === true)
                  ?.list.map((el, index) => (
                    <div
                      key={index}
                      className={'button--white button--contained'}
                      onClick={() => handleChangeAction(tab.id, el.value, el.label)}
                    >
                      {el.label}
                    </div>
                  ))
              : tab.list.map((el, index) => (
                  <div
                    key={index}
                    className={'button--white button--contained'}
                    onClick={() => handleChangeAction(tab.id, el.value, el.label)}
                  >
                    {el.label}
                  </div>
                ))}
          </div>
        </div>
      ))}
      <div 
      
      className="button--rose" onClick={redirectWhiteboard}>
        Go
      </div>
      
    </>
  );
};
