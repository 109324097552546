import { useExams } from 'providers/examProvider';
import ExamModal from './ExamModal';
import { useWhiteBoard } from '../WhiteBoardProvider';
import { useEffect } from 'react';

function PrivateExam({ userRole }) {
  const { setShowExam, setUrlExam, showExam, urlExam } = useExams();
  const { overlay, setOverlay, setOpenTest, isOpenExam, setIsOpenExam } = useWhiteBoard();

  const hundleClosePrivateModal = () => {
    setUrlExam('');
    setShowExam(false);
  };

  useEffect(() => {
    setOpenTest(false);
  }, [showExam]);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: showExam ? /*(overlay ? 0 : 2)*/ 0 : -1
        //zIndex: isOpenExam ? 99999 : -1
      }}
      onClick={() => {
        setOverlay(!overlay);
      }}
    >
      {showExam && <ExamModal examUrl={urlExam} onClose={hundleClosePrivateModal} />}
    </div>
  );
}

export default PrivateExam;
