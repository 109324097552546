import Tachometer from 'components/common/Tachometer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from './img/branak-logo-l.png';
import styles from './NewLandingPage.module.scss';
import { landingConstants } from './constants';
import MailerForm from './components/MailerForm';
import { Add, Minimize } from '@material-ui/icons';
import { ReactComponent as PauseIcon } from 'assets/img/pause-icon.svg';
import VisitorChatRoom from './Chat/visitorChatRoom';
import TeacherChatRoom from './Chat/teacherChatRoom';
import { playSound } from 'helpers/PlaySound';
import SoundConfig from 'components/Chat/SoundConfig';
import { EXAM_ID as examId } from '../Home/constants';
import './landingcss.css';
import { useVisibilityObserver } from 'utils/observer';
import { useExams } from 'providers/examProvider';

export default function NewLandingPage({ isAdmin }) {
  const history = useHistory();
  const [Playing, setPlaying] = useState(false);
  const [HideButton, setHideButton] = useState(false);
  const video = useRef();
  const [OpenTestimonies, setOpenTestimonies] = useState([]);
  const [floating, setfloating] = useState(false);
  const [heightBody, setHeightBody] = useState('');
  const [Menus, setMenus] = useState({
    chatList: false,
    icons: true
  });
  const [EXAM_ID, setEXAM_ID] = useState('');
  const { allExam } = useExams();

  useEffect(() => {
    if (allExam?.filesByUsers?.items?.length > 0) {
      // Filtrar los exámenes que están en la carpeta 'landing'
      const landingExams = allExam.filesByUsers.items.filter(
        exam => exam.currentFolder === 'landing'
      );

      // Ordenar los exámenes de la carpeta 'landing' por fecha de creación (descendente)
      const mostRecentExam = landingExams.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      )[0]; // Tomar el primero de la lista ordenada (el más reciente)

      // Establecer el ID del examen más reciente
      const id = mostRecentExam?.url || '';
      setEXAM_ID(id);
    }
  }, [allExam]);

  const handleRedirect = () => {
    history.push(`/take-exam/${EXAM_ID || examId}`);
  };

  // interseccion para agregar la animacion
  const { ref, inView } = useVisibilityObserver();

  //-------------------

  useEffect(() => {
    if (Playing) {
      setTimeout(() => {
        setHideButton(true);
      }, 3000);
    }
  }, [Playing]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
  }, []);

  const playVideo = () => {
    if (video.current.paused) {
      video.current.play();
    } else {
      video.current.pause();
    }
  };

  const openTestimony = id => {
    if (OpenTestimonies.includes(id)) {
      setOpenTestimonies(OpenTestimonies.filter(d => d !== id));
    } else {
      setOpenTestimonies(OpenTestimonies.concat([id]));
    }
  };

  const mouseOver = useCallback(() => {
    setHideButton(false);
  }, [Playing]);

  const mouseLeave = useCallback(() => {
    setTimeout(() => {
      if (Playing) setHideButton(true);
    }, 3000);
  }, [Playing]);
  return (
    <div className={styles.landingPageContainer}>
      <div className={styles.initialView}>
        <div className={styles.logoAndForm}>
          <img className={styles.logoImage} src={logo} alt="logoIcon" />
          <div className={styles.mailer}>
            <div className={styles.mailerForm}>
              <MailerForm />
            </div>
          </div>
        </div>
        <div className={styles.landingBottomBanner}>
          <p>Las clases de Inglés más personalizadas de toda la red</p>
        </div>
      </div>
      <div className={styles.initialViewResponsive}>
        <div className={styles.containers__image}>
          <img className={styles.logoImage} src={logo} alt="logoIcon" />
          <div className={styles.landingBottomBanner}>
            <p>Las clases de Inglés más personalizadas de toda la red</p>
          </div>
        </div>
        <div className={styles.containers}>
          <div className={styles.mailerForm}>
            <MailerForm floating={floating} setfloating={setfloating} />
          </div>
        </div>
      </div>

      {/**seccion */}
      <div className="container1">
        <div className="item">
          <div className="image-container">
            <img className="image" src={logo} alt="" />
          </div>
          <div>
            <p>No somos una escuela popular</p>
          </div>
        </div>

        <div className="item">
          <div className="image-container">
            <img className="image" src={logo} alt="" />
          </div>
          <div>
            <p>Somos una escuela exclusiva</p>
          </div>
        </div>
      </div>

      {/* seccion 2*/}
      <div className="section2-container">
        <div className="text-section">
          <h2>
            donde cada estudiante es un caso particular conducida por un linguista a la
            vez
          </h2>
        </div>
        <div className="text2-section">
          <div>
            <a href={landingConstants.links[0].link}>
              <img className="icon2" src={landingConstants.links[0].icon} alt="" />
            </a>
          </div>
          <h2>conversa sobre tu caso directo y en vivo con un experto</h2>
          <div>
            <a href={landingConstants.links[1].link}>
              <img className="icon2 " src={landingConstants.links[1].icon} alt="" />
            </a>
          </div>
        </div>
      </div>
      {/**seccion 3 */}

      <div className={`${styles.features1}  border-bottom shadow p-6`}>
        {landingConstants.features1.map((item, index) => {
          return (
            <div className={styles.items} key={index}>
              <img
                key={index}
                className={styles.itemsImage}
                src={item.icon}
                style={{ width: index === 2 ? 90 : 50, maxWidth: index === 2 ? 90 : 50 }}
                alt="entrevista"
              />
              <h2 className={styles.itemText}> {item.text} </h2>
            </div>
          );
        })}
      </div>

      {/**seccion 4 */}
      <div ref={ref} className="text-center bg-secondary-subtle container p-5 mt-4">
        <h2 className="">Nuestros estudiantes</h2>
        <div className="container-card">
          {landingConstants.testimonies.map((item, i) => {
            return (
              <div
                className={`m-5 ${
                  !OpenTestimonies.includes(i) ? 'testimonios1' : 'testimonios'
                } Regular shadow p-3 rounded`}
              >
                <div className="d-flex">
                  <div>
                    <img className="" src={logo} alt={item.owner} />
                  </div>
                  <div>
                    <div>
                      <p>{item.name}</p>
                    </div>
                    <div
                      className={`${
                        OpenTestimonies.includes(i) ? 'vertestimonio' : 'texttestimonio'
                      }`}
                    >
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
                <div className="m-auto">
                  {!OpenTestimonies.includes(i) ? (
                    <Add
                      onClick={() => openTestimony(i)}
                      className={styles.testimonyArrow}
                      style={{ fontSize: '30' }}
                    />
                  ) : (
                    <Minimize
                      onClick={() => openTestimony(i)}
                      className={styles.testimonyArrow__upsideDown}
                      style={{ fontSize: '30' }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.videoAndTachometer}>
        <div className={styles.videoContainer}>
          <video
            ref={video}
            className={styles.video}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            src="https://branak-files225457-dev.s3.amazonaws.com/cartoon_ad.mp4"
            type="video/mp4"
            onClick={playVideo}
            onMouseOver={mouseOver}
            onMouseLeave={mouseLeave}
          />
          {Playing ? (
            <PauseIcon
              onClick={playVideo}
              onMouseOver={mouseOver}
              className={HideButton ? styles.pauseIcon__hide : styles.pauseIcon}
            />
          ) : (
            <div onClick={playVideo} className={styles.arrowRight} />
          )}
        </div>
        <div onClick={handleRedirect} className={styles.tachometerInLanding}>
          <Tachometer
            landingPageLink={window.location.href + `/take-exam/${EXAM_ID}`}
            title="Prueba tu nivel"
            defaultDimentions={{ width: 370, height: 200 }}
            forceBackgroundColor={'yellow'}
            archText="TEST YOUR ENGLISH"
          />
          <div className={styles.btnStartTest}>START</div>
        </div>
      </div>
      <div className={styles.links}>
        <p className={styles.text}>Pulsa para contactar a un profesor</p>
        <div className={styles.links__links}>
          {landingConstants.links.map((item, index) => {
            return (
              <a
                key={index}
                href={item.link}
                className={styles.linkButton}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon} alt="link-icon" />
              </a>
            );
          })}
        </div>
      </div>

      <div className={styles.listsContainer}>
        <p className={styles.title}>¿Quieres más?</p>
        <div className={styles.lists}>
          <div className={styles.list}>
            {landingConstants.wantMore.list1.map((item, index) => {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
          </div>
          <div className={styles.list}>
            {landingConstants.wantMore.list2.map((item, index) => {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
          </div>
        </div>
      </div>

      <div className={styles.links}>
        <p className={styles.text}>Pulsa para contactar a un profesor</p>
        <div className={styles.links__links}>
          {landingConstants.links.map((item, index) => {
            return (
              <a
                key={index}
                href={item.link}
                className={styles.linkButton}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon} alt="link-icon" />
              </a>
            );
          })}
        </div>
        {!isAdmin && <VisitorChatRoom />}
        {isAdmin && (
          <TeacherChatRoom
            heightBody={heightBody}
            hideIcons={() => setMenus({ ...Menus, icons: false })}
            close={() => {
              setTimeout(() => {
                setMenus({ icons: true, chatList: false });
              }, 500);
            }}
            iconClick={() => {
              playSound(SoundConfig.chat_hidden_audio);
              setMenus({ icons: false, chatList: true });
            }}
            openChatAutomatically={() => {
              setMenus({ icons: false, chatList: true });
            }}
            hideComponent={!Menus.chatList}
            hideInput={Menus.icons}
            showPlus={Menus.icons}
            newPositionH={() => {}}
            activeColorChat={() => {}}
          />
        )}
      </div>
      {/**whatsapp */}
      <div className={'wppFloat'}>
        <a href={landingConstants.links[0].link}>
          <img className="social-icon" src={landingConstants.links[0].icon} alt="" />
        </a>
      </div>
      {/**seccion proceso */}
      <div className="process-container">
        <p className="process-title">¿Cómo empiezas?</p>
        <div ref={ref} className="process-steps">
          {landingConstants.proceso[0].steps.map((step, key) => (
            <div
              key={key}
              className={` ${inView ? 'step-item step-item-visible' : 'step-item'}`}
            >
              <img className="step-icon" src={step.icon} alt="" />
              <div>
                <p>{step.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/**seccion footer */}
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img alt="logoIcon" src={logo} className="logo-icon" />
            <p>2024</p>
          </div>
          <div className="footer-sections">
            {/* Sección Contactos */}
            <div>
              <p className="section-title">Contáctanos</p>
              <ul className="list">
                <li>Email: contacto@empresa.com</li>
                <li>Teléfono: +52 (123) 456-7890</li>
                <li>Dirección: Calle Ficticia #123, Ciudad, País</li>
              </ul>
            </div>
            {/* Sección Enlaces Útiles */}
            <div>
              <p className="section-title">Enlaces Útiles</p>
              <ul className="list">
                <li>
                  <a href="/about-us" className="link">
                    Acerca de Nosotros
                  </a>
                </li>
                <li>
                  <a href="/services" className="link">
                    Servicios
                  </a>
                </li>
                <li>
                  <a href="/contact" className="link">
                    Contáctanos
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" className="link">
                    Política de Privacidad
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions" className="link">
                    Términos y Condiciones
                  </a>
                </li>
              </ul>
            </div>
            {/* Sección Sobre Nosotros */}
            <div>
              <p className="section-title">Sobre Nosotros</p>
              <ul className="list">
                <li>Compañía dedicada a ofrecer servicios de calidad desde [Año].</li>
                <li>Nuestro compromiso es tu satisfacción.</li>
              </ul>
            </div>
            {/* Sección Redes Sociales */}
            <div>
              <p className="section-title">Síguenos</p>
              <ul className="social-links">
                {landingConstants.links.map((link, key) => (
                  <li key={key}>
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <img src={link.icon} alt="" className="social-icon" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
