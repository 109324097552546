import { useEffect, useState } from 'react';
import { useUserRole } from 'services/cognito.service';
import { USER_ROLES } from 'enums/constants.enum';
import { IPHONE_WIDTH } from 'enums/constants.enum';
import { UpdateMessages } from './updateMessages';
import { CreateMessages } from './createMessages';
import { ReceivedMessages } from './receivedMessages';
import { BackgroundNote } from './backgroundNote';
import editProfileIcon from '../assets/plus-icon.svg'; // Icono de agregar nota
import closed from '../assets/cerrar.svg'; // Icono de cerrar
import { useQuery } from '@apollo/client';
import { GET_USER_VISITING } from 'components/UserManagment/StudentProfile/graphql/queries';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment-timezone';
import { useSocket } from 'providers/SocketProvider';
import { useWhiteBoard } from 'components/Whiteboard/WhiteBoardProvider';
import { useUser } from 'components/UserManagment/UserProvider';

export const Note = ({
  bgColor,
  refetch,
  openNotes,
  setOpenNotes,
  note,
  setNotesLength,
  role
}) => {
  //const role = useUserRole();
  const { userId } = useParams();
  const [isPhone, setIsPhone] = useState(window.innerWidth < IPHONE_WIDTH);
  const [isEditMode, setIsEditMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idUpdate, setIdUpdate] = useState(null);
  const { user } = useUser();
  //const [translate, setTranslate] = useState(null);
  const { data: userDataVisiting } = useQuery(GET_USER_VISITING, {
    variables: { id: userId }
  });

  const { whiteBoardSocket } = useSocket();
  const backgroundColor = windowWidth < 901 ? 'transparent' : bgColor;
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    setIsPhone(window.innerWidth < IPHONE_WIDTH);
  }, [windowWidth]);

  const openCreateNote = () => {
    setIsEditMode(true);
    setIsUpdate(false);
  };

  const closedCreateNote = () => {
    setIsUpdate(false);
    setIsEditMode(false);
    setOpenNotes(false); // Cerrar las notas cuando se cierra el editor
  };

  const handleToggleNotes = () => {
    setOpenNotes(openNotes => !openNotes);
  };

  //funcion para filtrar las notas de los ultimos dias
  const date = moment().tz('America/Santo_Domingo');
  const dateMilisecond = date.valueOf();

  const filterNotesLastDays = (days, data = []) => {
    if (data === undefined) {
      // Si data es undefined, devolver un arreglo vacío
      return [];
    }

    const milliseconds = days * 24 * 60 * 60 * 1000;
    const lastDays = dateMilisecond - milliseconds;
    const notesLastDays = data.filter(message => parseInt(message.date) >= lastDays);

    return notesLastDays;
  };

  useEffect(() => {
    let notas = filterNotesLastDays(10, note?.listTextNotes?.items);
    notas = notas.filter(el => {
      return el.createBy === (userDataVisiting?.id ?? user?.attributes?.sub)
        ? true
        : el.role === (!userDataVisiting?.id && role)
        ? true
        : el.role === (userDataVisiting?.id ?? user?.attributes?.sub)
        ? true
        : el.role === (!userDataVisiting?.id && 'ALL')
        ? role === USER_ROLES.ADMINS && el.role !== 'default'
        : false;
    });

    if (notas.length > 0) {
      setTimeout(() => {
        setOpenNotes(true);
      }, 600);
      return;
    }
  }, [note]);

  return (
    <div
      className={
        isPhone
          ? `notesTrue ${openNotes ? 'translateNote' : 'currentTranslate'}`
          : `notes ${openNotes ? 'translateNote' : 'currentTranslate'}`
      }
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={isPhone ? 'createModeTrue' : 'createMode'}>
        {!isEditMode && (
          <img
            src={editProfileIcon}
            alt="Create a note"
            title="Create a note"
            onClick={openCreateNote}
            className="icon-small"
          />
        )}
        <img
          src={closed}
          alt="Close note"
          title="Close note"
          onClick={closedCreateNote}
          className="icon-small"
        />
        {isEditMode && role === USER_ROLES.ADMINS && <BackgroundNote />}
      </div>

      {!isEditMode ? (
        <div className="notesNormal">
          {openNotes && (
            <ReceivedMessages
              activePhoneMode={() => setIsPhone(!isPhone)}
              userDataVisiting={userDataVisiting?.getUser}
              setIsEditMode={setIsEditMode}
              setIsUpdate={setIsUpdate}
              setIdUpdate={setIdUpdate}
              isPhone={isPhone}
              setNotesLength={setNotesLength}
              note={note}
              refetch={refetch}
              openNotes={openNotes}
              role={role}
            />
          )}
        </div>
      ) : (
        <div className={isPhone ? 'notesEditTrue' : 'notesEdit'}>
          {isUpdate && <p className="createNote">Edit</p>}
          {isEditMode && isUpdate ? (
            <UpdateMessages
              setIsEditMode={setIsEditMode}
              userDataVisiting={userDataVisiting?.getUser}
              idUpdate={idUpdate}
              isPhone={isPhone}
              setIsUpdate={setIsUpdate}
              refetch={refetch}
            />
          ) : (
            <CreateMessages
              setIsEditMode={setIsEditMode}
              userDataVisiting={userDataVisiting?.getUser}
              isPhone={isPhone}
              setIdUpdate={setIdUpdate}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </div>
  );
};
