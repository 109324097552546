import React, { useContext } from 'react';

import LiveBlack from 'assets/whiteboard/black/live-black.svg';
import LiveGrey from 'assets/whiteboard/grey/live-grey.svg';
import LiveWhite from 'assets/whiteboard/white/live-white.svg';

import { VideoConferenceContext } from '../VideoConferenceContext';

import './styles.css';

const pathSegments = window.location.pathname.split('/');
const incognito = pathSegments.includes('incognito') || false;

const LiveIcon = ({ color, animation }) => {
  const { getTokenVideoTwilio } = useContext(VideoConferenceContext);
  if (color === 'black') {
    return (
      <div
      style={{ display: "grid", justifyContent: "center", alignItems: "center" }}
        className={animation ? 'live-container cbutton--effect-jagoda' : 'live-container'}
      >
        
        <img
          src={LiveBlack}
          className={animation ? 'live-icon cbutton--effect-jagoda' : 'live-icon'}
          onClick={() => getTokenVideoTwilio()}
        />
        {incognito && <div style={{ marginLeft: "-10px", color: color, fontWeight: "bold"}} className='incognito'>Incognito</div>}
      </div>
    );
  }

  if (color === 'grey') {
    return (
      <div 
      style={{ display: "grid", justifyContent: "center", alignItems: "center" }}
      className="live-container">
        
        <img src={LiveGrey} className="live-icon" onClick={() => getTokenVideoTwilio()} />
        {incognito && <div style={{ marginLeft: "-10px", color: color, fontWeight: "bold"}} className='incognito'>Incognito</div>}
      </div>
    );
  }

  if (color === 'white') {
    return (
      <div 
      style={{ display: "grid", justifyContent: "center", alignItems: "center" }}
      className="live-container">
      
        <img
          src={LiveWhite}
          className="live-icon"
          onClick={() => getTokenVideoTwilio()}
        />
        {incognito && <div style={{ marginLeft: "-10px", color: color, fontWeight: "bold"}} className='incognito'>Incognito</div>}
      </div>
    );
  }
};

export default LiveIcon;
